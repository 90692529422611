import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Tables.module.css'; // Import your CSS module for styling
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';

function AdminTable() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        department: '',
        designation: '',
        permissions: {
            customer: { editor: false, viewer: false },
            admin: { editor: false, viewer: false },
            partners: { editor: false, viewer: false },
            transports: { editor: false, viewer: false },
            boq: { editor: false, viewer: false },
            lead: { editor: false, viewer: false },
        },
    });
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Simulate current user's permissions
    const currentUserPermissions = {
        admin: true, // Change this to `false` to test restricted access
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/users`);
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
        resetForm();
    };

    const openEditModal = (user) => {
        setSelectedUser(user);
        setFormData(user); // Prefill form data with the selected user data
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedUser(null);
        resetForm();
    };

    const resetForm = () => {
        setFormData({
            name: '',
            phoneNumber: '',
            email: '',
            department: '',
            designation: '',
            permissions: {
                customer: { editor: false, viewer: false },
                admin: { editor: false, viewer: false },
                partners: { editor: false, viewer: false },
                transports: { editor: false, viewer: false },
                boq: { editor: false, viewer: false },
                lead: { editor: false, viewer: false },
            },
        });
        setErrors({});
        setCurrentStep(1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handlePermissionChange = (e, category, type) => {
        const { checked } = e.target;
    
        setFormData((prev) => ({
            ...prev,
            permissions: {
                ...prev.permissions,
                [category]: {
                    editor: type === 'editor' ? checked : false, // Set viewer to false when editor is checked
                    viewer: type === 'viewer' ? checked : false, // Set editor to false when viewer is checked
                },
            },
        }));
    };
    

    const validateForm = () => {
        const tempErrors = {};
        if (!formData.name) tempErrors.name = 'Name is required';
        if (!formData.phoneNumber) tempErrors.phoneNumber = 'Valid phone no is required';
        if (!formData.email) tempErrors.email = 'Valid email is required';
        if (!formData.department) tempErrors.department = 'Department is required';
        if (!formData.designation) tempErrors.designation = 'Designation is required';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            await axios.put(`${API_BASE_URL}/users/${selectedUser._id}`, formData);
            fetchData();
            closeEditModal();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const response = await axios.post(`${API_BASE_URL}/users`, formData);
            setUsers((prev) => [...prev, response.data]);
            closeModal();
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const handleStatusChange = async (e, user) => {
        const { value } = e.target;
        try {
            await axios.patch(`${API_BASE_URL}/users/${user._id}/status`, { status: value });
            fetchData();
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const filteredUsers = users.filter(
        (user) =>
            user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className={styles.tableContainer}>
            <div className={styles.layoutBar}>
            <div className={styles.searchBar}>
                <input
                    type="text"
                    placeholder="Search by Name or Email"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className={styles.searchInput}
                />
                </div>
                <button onClick={openModal} className={styles.addCustomerButton}>
                    + Add User
                </button>
            </div>


            

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>Designation</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user) => (
                        <tr key={user._id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.department}</td>
                            <td>{user.designation}</td>
                            <td>
                                {currentUserPermissions.admin ? (
                                    <select
                                        value={user.status}
                                        onChange={(e) => handleStatusChange(e, user)}
                                    >
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                ) : (
                                    user.status
                                )}
                            </td>
                            <td>
                                <button onClick={() => openEditModal(user)}>View/Edit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <AddUserModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                formData={formData}
                errors={errors}
                passwordVisible={passwordVisible}
                currentStep={currentStep}
                handleChange={handleChange}
                setPasswordVisible={setPasswordVisible}
                nextStep={() => setCurrentStep((prev) => prev + 1)}
                prevStep={() => setCurrentStep((prev) => prev - 1)}
                handlePermissionChange={handlePermissionChange}
                handleSubmit={handleSubmit}
                closeModal={closeModal}
            />

            <EditUserModal
                isOpen={isEditModalOpen}
                onRequestClose={closeEditModal}
                formData={formData}
                errors={errors}
                currentStep={currentStep}
                handleChange={handleChange}
                handlePermissionChange={handlePermissionChange}
                nextStep={() => setCurrentStep((prev) => prev + 1)}
                prevStep={() => setCurrentStep((prev) => prev - 1)}
                handleSubmit={handleEditSubmit}
            />
        </div>
    );
}

export default AdminTable;
