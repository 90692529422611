import React, { useEffect, useRef } from 'react';
import styles from './TaskModal.module.css';

const TaskModal = ({ task, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close the modal
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={styles.modalOverlay}>
      <div ref={modalRef} className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose} aria-label="Close modal">
          &times;
        </button>
        <h2 className={styles.modalTitle}>
          Title: {task.title}
          <span
            className={styles.priorityBadge}
            style={{
              backgroundColor:
                task.priority === 'P1'
                  ? '#81c784' // Green for P1
                  : task.priority === 'P2'
                  ? '#ffb74d' // Orange for P2
                  : '#e57373', // Red for other priorities
            }}
          >
            {task.priority}
          </span>
        </h2>

        <div className={styles.description}>
          <strong>Description:</strong>
          <p>{task.description}</p>
        </div>

        <div className={styles.taskDetails}>
          <div className={styles.detailItem}>
            <strong>Due Date:</strong>
            <span>{new Date(task.dueDate).toLocaleDateString()}</span>
          </div>

          <div className={styles.detailItem}>
            <strong>Assignees:</strong>
            <div className={styles.assignees}>
              {Array.isArray(task.assignedTo) && task.assignedTo.length > 0 ? (
                task.assignedTo.map((assignee, index) => (
                  <div key={index} className={styles.assigneeAvatar}>
                    <img
                      src={assignee.profilePicture || 'https://via.placeholder.com/40'}
                      alt={assignee.name}
                      title={assignee.name}
                    />
                  </div>
                ))
              ) : (
                <span>No assignees</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
