import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from './Siteinspection.module.css';
import QuestionaireInspection from '../components/Questionaireinspection/Questionaireinspection';
import SideNavigation from "../components/SideNavigation/SideNavigation";
import Header from "../components/Header/Header";

const Siteinspection = () => {
  const location = useLocation();
  const { lead } = location.state || {}; // Keep only the lead from location state
  const [inspectionData, setInspectionData] = useState({ createdBy: 'N/A', date: 'N/A' });
  const [currentUser, setCurrentUser] = useState({ name: 'N/A', email: 'N/A' }); // Current logged-in user
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch current user (mock or replace with your auth system)
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/currentuser`); // Replace with your auth endpoint
        if (response?.data) {
          setCurrentUser({
            name: response.data.name || 'N/A',
            email: response.data.email || 'N/A',
          });
        }
      } catch (error) {
        console.error('Failed to fetch current user:', error);
      }
    };

    fetchCurrentUser();
  }, [API_BASE_URL]);

  useEffect(() => {
    const fetchInspectionData = async () => {
      if (lead?._id) {
        try {
          const response = await axios.get(`${API_BASE_URL}/siteinspection/lead/${lead._id}`);
          if (response?.data) {
            setInspectionData({
              createdBy: response.data.createdBy || `${currentUser.name} (${currentUser.email})`,
              date: response.data.date || 'N/A',
            });
          } else {
            setInspectionData({
              createdBy: `${currentUser.name} (${currentUser.email})`,
              date: 'N/A',
            });
          }
        } catch (error) {
          console.error('Failed to fetch inspection data:', error);
        }
      }
    };

    fetchInspectionData();
  }, [lead, API_BASE_URL, currentUser]);

  return (
    <div>
      <Header />
      <div className={styles.container}>
        <SideNavigation />
        <QuestionaireInspection
          className={styles.headerContainer}
          lead={lead} // Pass lead as a prop
          createdBy={inspectionData.createdBy} // Use data from API or current user
          date={inspectionData.date} // Use data from API
          onSave={(answers) => console.log("Saved answers:", answers)}
        />
      </div>
    </div>
  );
};

export default Siteinspection;
