import React from 'react';
import Header from "../components/Header/Header";
import SideNavigation from "../components/SideNavigation/SideNavigation";
import TeamTable from '../components/Team/TeamTable'; // Import TeamTable
import styles from './Pages.module.css'; // Import the CSS module

const Team = ({ lead }) => { // Assuming lead is passed as a prop here
  return (
    <div className={styles.container}>
      <Header />
      <SideNavigation />
      <TeamTable />
    </div>
  );
};

export default Team;
