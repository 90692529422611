import React from 'react';
import styles from './SiteInspectionHeader.module.css';

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  try {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(date);
  } catch (error) {
    console.error("Invalid date format:", dateString);
    return "Invalid Date";
  }
};

const SiteInspectionHeader = ({ date, createdBy, onButtonClick }) => {
  return (
    <div className={styles.siteInspectionHeader}>
      <div className={styles.infoSection}>
        <div>
          <strong>Date:</strong> <span>{formatDate(date)}</span>
        </div>
        <div>
          <strong>Created by:</strong> <span>{createdBy || "N/A"}</span>
        </div>
      </div>
      <button className={styles.addInspectionButton} onClick={onButtonClick}>
        Preview
      </button>
    </div>
  );
};

export default SiteInspectionHeader;
