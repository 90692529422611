import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import LeadHeader from '../LeadDetails/LeadHeader';
import Teamheader from './Teamheader';
import TaskForm from './TaskForm';
import TaskModal from './TaskModal';
import axios from 'axios';
import styles from './TeamTable.module.css';
import { FaEllipsisV } from 'react-icons/fa'; // Import a three-dot icon from react-icons

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TeamTable = () => {
  const location = useLocation();
  const lead = location.state?.lead;

  const [tasks, setTasks] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null); // For Task Modal
  const [selectedTaskForStatus, setSelectedTaskForStatus] = useState(null); // For Status Container
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  if (!lead || !lead._id) {
    return <p>Lead data is not available</p>;
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [tasksResponse, membersResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/tasks/${lead._id}`),
          axios.get(`${API_BASE_URL}/teams/lead/${lead._id}/members`)
        ]);

        if (Array.isArray(tasksResponse.data)) {
          setTasks(tasksResponse.data);
        } else {
          console.error('Unexpected tasks response format:', tasksResponse.data);
        }

        if (membersResponse.data.success) {
          setTeamMembers(membersResponse.data.members);
        } else {
          console.error('Failed to fetch team members:', membersResponse.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lead._id]);

  const addTask = (newTask) => {
    setTasks([...tasks, newTask]);
  };

  const updateTask = (updatedTask) => {
    setTasks(tasks.map((task) => (task._id === updatedTask._id ? updatedTask : task)));
    setEditingTask(null);
  };

  const handleStatusChange = async (taskId, newStatus) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/tasks/${taskId}`, { status: newStatus });
      if (response.data.success) {
        setTasks(tasks.map((task) => (task._id === taskId ? { ...task, status: newStatus } : task)));
        setSelectedTaskForStatus(null); // Close the status modal after updating
      } else {
        console.error('Error updating task status:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating task status:', error.message || error);
    }
  };
  

  const getAssigneeNames = (assignedTo) => {
    if (!Array.isArray(assignedTo)) return '';
    return assignedTo.map((assignee) => assignee.name).join(', ');
  };

  const teamMemberOptions = useMemo(() => {
    return teamMembers.map((member) => ({
      value: member.email,
      label: member.name,
    }));
  }, [teamMembers]);

  return (
    <div className={styles.tableContainer}>
      <LeadHeader activeTab="team" lead={lead} />
      <Teamheader className={styles.teamHeader} addTask={addTask} leadId={lead._id} />

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className={styles.errorMessage}>{error}</p>
      ) : (
        <>
          <table className={styles.teamTable} style={{ marginTop: 20 }}>
            <thead>
              <tr>
                <th>To do</th>
                <th>Task</th>
                <th>Description</th>
                <th>Due Date</th>
                <th>Priority</th>
                <th>Assignees</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
  {tasks.length > 0 ? (
    tasks.map((task) => (
      <tr
        key={task._id}
        onClick={(e) => {
          // Open the task modal only when the row is clicked, not the radio button or status container
          if (!e.target.closest(`.${styles.radioWrapper}`)) {
            setSelectedTask(task);
          }
        }}
      >

{/* Status Column with Radio Button */}
<td style={{ position: 'relative' }}>
  <div
    className={styles.radioWrapper}
    onClick={(e) => {
      e.stopPropagation(); // Prevent row click
      setSelectedTaskForStatus((prev) =>
        prev && prev._id === task._id ? null : task
      ); // Toggle status container
    }}
  >
    <input
      type="radio"
      id={`radio-${task._id}`}
      name={`task-status-${task._id}`}
      checked={selectedTaskForStatus?._id === task._id}
      onChange={() => {}}
    />
    <label htmlFor={`radio-${task._id}`} className={styles.radioLabel}></label>
  </div>

  {/* Status Update Popup */}
  {selectedTaskForStatus?._id === task._id && (
    <div
      className={styles.statusPopup}
      onClick={(e) => e.stopPropagation()} // Prevent row click from closing the popup
    >
      <h4>Status</h4>
      <div className={styles.radioGroup}>
        <label>
          <input
            type="radio"
            name={`status-${task._id}`}
            checked={task.status === 'Not Started'}
            onChange={() => handleStatusChange(task._id, 'Not Started')}
          />
          Not Started
        </label>
        <label>
          <input
            type="radio"
            name={`status-${task._id}`}
            checked={task.status === 'In Progress'}
            onChange={() => handleStatusChange(task._id, 'In Progress')}
          />
          In Progress
        </label>
        <label>
          <input
            type="radio"
            name={`status-${task._id}`}
            checked={task.status === 'Completed'}
            onChange={() => handleStatusChange(task._id, 'Completed')}
          />
          Completed
        </label>
      </div>
    </div>
  )}
</td>


        {/* Title Column */}
        <td>
          {task.title.length > 20 ? `${task.title.substring(0, 20)}...` : task.title}
        </td>

        {/* Description Column */}
        <td>
          {task.description.length > 60
            ? `${task.description.substring(0, 60)}...`
            : task.description}
        </td>

        {/* Due Date Column */}
        <td>{new Date(task.dueDate).toLocaleDateString()}</td>

        {/* Priority Column */}
        <td className={styles.priorityCell}>
          <span
            style={{
              backgroundColor:
                task.priority === 'P1'
                  ? '#e57373' // Red for P1
                  : task.priority === 'P2'
                  ? '#ffb74d' // Orange for P2
                  : task.priority === 'P3'
                  ? '#81c784' // Green for P3
                  : '#bdbdbd', // Grey for undefined priorities
              color: 'white',
              padding: '2px 6px',
              borderRadius: '4px',
            }}
          >
            {task.priority}
          </span>
        </td>

        {/* Assignees Column */}
        <td>
          {task.assignedTo && task.assignedTo.length > 0 ? (
            <div className={styles.assigneeStack}>
              {task.assignedTo.map((assignee) => (
                <img
                  key={assignee._id}
                  src={assignee.profilePicture || 'https://via.placeholder.com/40'}
                  alt={assignee.name}
                  title={assignee.email}
                  className={styles.assigneePicture}
                />
              ))}
            </div>
          ) : (
            'No Assignees'
          )}
        </td>

        {/* Status Column */}
        <td>
          <span
            style={{
              backgroundColor:
                task.status === 'Not Started'
                  ? '#f0f4c3' // Light yellow for Not Started
                  : task.status === 'In Progress'
                  ? '#64b5f6' // Light blue for In Progress
                  : task.status === 'Completed'
                  ? '#a5d6a7' // Light green for Completed
                  : '#bdbdbd', // Grey for undefined status
              color: 'black',
              padding: '2px 6px',
              borderRadius: '4px',
            }}
          >
            {task.status}
          </span>
        </td>

        <td>
      <button
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click
          setEditingTask(task);
        }}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: '0',
        }}
      >
        <FaEllipsisV size={20} color="#000" /> {/* Three-dot icon */}
      </button>
    </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8">No tasks found for this lead</td>
    </tr>
  )}
</tbody>


          </table>

          {editingTask && (
            <TaskForm
              initialTaskData={editingTask}
              leadId={lead._id}
              onSave={updateTask}
              onCancel={() => setEditingTask(null)}
              teamMemberOptions={teamMemberOptions}
            />
          )}

          {selectedTask && (
            <TaskModal
              task={selectedTask}
              onClose={() => setSelectedTask(null)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TeamTable;
