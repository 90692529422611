import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import styles from './Questionaireinspection.module.css';
import LeadHeader from '../LeadDetails/LeadHeader';
import SiteInspectionHeader from './SiteInspectionHeader';

const QuestionaireInspection = ({ lead, createdBy, date, inspectionId }) => {
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isEditing, setIsEditing] = useState(false); // Editing state
  const [inspectionData, setInspectionData] = useState(null); // Existing data
  const [showPreviewOnly, setShowPreviewOnly] = useState(false); // Toggle preview/form

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const questions = useMemo(() => [
    "Offsets provided by left, right, and back neighbours?",
    "Electric Pole/Transformer location near to site?",
    "Existing infrastructure on site which needs to be demolished?",
    "At least 64 sqft area available for labour shed?",
    "Is labour allowed to stay at the site?",
    "10% of site area for storage of materials?",
    "Is there any rocky terrain present on the site, shared with neighbouring building?",
    "Is Site contour provided?",
    "Is there any rocky terrain present on site?",
    "Is there any slope in the plot?",
    "Difference in level of the site with respect to the centre of the road?",
    "Road traffic conditions at the plot?",
    "What is the road width in front of the plot (excluding footpath)?",
    "If Yes, is the storage area available near the site?",
    "Water table high?",
    "Drainage Line marking and information provided?",
  ], []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        let response;

        if (inspectionId) {
          response = await axios.get(`${API_BASE_URL}/siteinspection/${inspectionId}`);
        } else if (lead?._id) {
          response = await axios.get(`${API_BASE_URL}/siteinspection/lead/${lead._id}`);
        }

        if (response?.data) {
          setInspectionData(response.data);
          const fetchedAnswers = response.data.answers;

          const mappedAnswers = questions.map((question) => {
            const answer = fetchedAnswers.find((a) => a.question === question);
            return answer || { question, selection: "", uom: "", yesNo: "" };
          });

          setAnswers(mappedAnswers);
          setIsEditing(true);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          const initialAnswers = questions.map((question) => ({
            question,
            selection: "",
            uom: "",
            yesNo: "",
          }));
          setAnswers(initialAnswers);
          setIsEditing(false);
        } else {
          console.error('Failed to fetch site inspection:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [inspectionId, lead?._id, API_BASE_URL, questions]);

  const handleChange = (index, key, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index][key] = value || "";
    setAnswers(updatedAnswers);
  };

  const handleSave = async () => {
    const validDate = date && !isNaN(new Date(date).getTime()) ? new Date(date) : new Date(); // Use current date if invalid
  
    const payload = {
      leadId: lead?._id,
      createdBy: createdBy || "Unknown",
      date: validDate,
      answers,
    };
  
    try {
      setIsLoading(true);
  
      if (isEditing && inspectionData?._id) {
        const response = await axios.put(`${API_BASE_URL}/siteinspection/${inspectionData._id}`, payload);
        if (response.status === 200) {
          alert("Site Inspection updated successfully!");
        }
      } else {
        const response = await axios.post(`${API_BASE_URL}/siteinspection/save`, payload);
        if (response.status === 200) {
          alert("Site Inspection saved successfully!");
          setAnswers([]);
        }
      }
    } catch (error) {
      console.error("Failed to save site inspection:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const togglePreview = () => {
    setShowPreviewOnly((prev) => !prev);
  };

  return (
    <div>
      <LeadHeader activeTab="siteInspection" lead={lead} />
      <SiteInspectionHeader
        date={date}
        createdBy={createdBy}
        onButtonClick={togglePreview}
        isPreviewEnabled={!!inspectionData}
      />

      {showPreviewOnly ? (
        <div className={styles.previewContainer}>
          <table className={styles.previewTable} style={{ marginTop: 0 }}>
            <thead>
              <tr>
                <th>Description</th>
                <th>UOM</th>
                <th>Standard</th>
              </tr>
            </thead>
            <tbody>
              {answers.map((answer, index) => (
                <tr key={index}>
  <td>{answer.question.charAt(0).toUpperCase() + answer.question.slice(1)}</td>
  <td>{answer.selection.charAt(0).toUpperCase() + answer.selection.slice(1)}</td>
  <td>
    {answer.selection === 'uom'
      ? (answer.uom ? answer.uom.charAt(0).toUpperCase() + answer.uom.slice(1) : 'N/A')
      : (answer.yesNo ? answer.yesNo.charAt(0).toUpperCase() + answer.yesNo.slice(1) : 'N/A')}
  </td>
</tr>

              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.containerSiteIns}>
          <div className={styles.questionGridSiteIns}>
            {questions.map((question, index) => (
              <div key={index} className={styles.questionItemSiteIns}>
                <label>{question}</label>
                <div className={styles.inputsSiteIns}>
                  <select
                    className={styles.inputSiteIns}
                    value={answers[index]?.selection || ""}
                    onChange={(e) => handleChange(index, "selection", e.target.value)}
                  >
                    <option value="">Select Type</option>
                    <option value="uom">UOM</option>
                    <option value="yesNo">Yes/No</option>
                  </select>
                  {answers[index]?.selection === "uom" && (
                    <select
                      className={styles.inputSiteIns}
                      value={answers[index]?.uom || ""}
                      onChange={(e) => handleChange(index, "uom", e.target.value)}
                    >
                      <option value="">Select Unit</option>
                      <option value="ft">Feet</option>
                      <option value="m">Meters</option>
                    </select>
                  )}
                  {answers[index]?.selection === "yesNo" && (
                    <select
                      className={styles.inputSiteIns}
                      value={answers[index]?.yesNo || ""}
                      onChange={(e) => handleChange(index, "yesNo", e.target.value)}
                    >
                      <option value="">Select Option</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button
            className={styles.saveButtonSiteIns}
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : isEditing ? 'Update' : 'Save'}
          </button>
        </div>
      )}
    </div>
  );
};

export default QuestionaireInspection;
