import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import styles from './Teamheader.module.css';

const formatDateForInput = (isoDate) => {
  if (!isoDate) return ''; // Handle null or undefined dates
  const date = new Date(isoDate);
  return date.toISOString().split('T')[0]; // Extracts the date part in 'yyyy-MM-dd'
};

const TaskForm = ({ initialTaskData = {}, leadId, onSave, onCancel, teamMemberOptions }) => {
  const [task, setTask] = useState({
    taskName: '',
    description: '',
    assignees: [],
    dueDate: '',
    priority: 'P1',
    status: 'Not Started',
    ...initialTaskData,
  });
  const [errors, setErrors] = useState({ taskName: '', description: '' });

  useEffect(() => {
    if (initialTaskData && Object.keys(initialTaskData).length > 0) {
      setTask({
        taskName: initialTaskData.title || '',
        description: initialTaskData.description || '',
        assignees: initialTaskData.assignedTo?.map((assignee) => ({
          value: assignee.email,
          label: assignee.name,
        })) || [],
        dueDate: initialTaskData.dueDate ? formatDateForInput(initialTaskData.dueDate) : '',
        priority: initialTaskData.priority || 'P1',
        status: initialTaskData.status || 'Not Started',
      });
    }
  }, [initialTaskData]);

  const handleTaskSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};
    if (!task.taskName) validationErrors.taskName = 'Task Name is required.';
    if (!task.description) validationErrors.description = 'Description is required.';

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const assignees = task.assignees.map((assignee) => ({
      name: assignee.label,
      email: assignee.value,
    }));

    const payload = {
      leadId: leadId || null,
      title: task.taskName,
      description: task.description,
      dueDate: task.dueDate || null,
      assignedBy: 'admin',
      assignedTo: assignees,
      priority: task.priority,
      status: task.status,
    };

    try {
      const url = initialTaskData._id
        ? `${process.env.REACT_APP_API_BASE_URL}/tasks/${initialTaskData._id}`
        : `${process.env.REACT_APP_API_BASE_URL}/tasks`;

      const method = initialTaskData._id ? 'put' : 'post';
      const response = await axios[method](url, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (response.data && response.data.success) {
        onSave(response.data.task);
        onCancel();
      } else {
        console.error('Failed to save task:', response.data.message || 'Unexpected error');
      }
    } catch (error) {
      console.error('Error saving task:', error.message || error);
    }
  };

  const today = new Date().toISOString().split('T')[0];

return (
<div className={styles.taskForm}>
  <h3>{initialTaskData._id ? 'Edit Task' : 'Create Task'}</h3>
  <form onSubmit={handleTaskSubmit}>
    <div className={styles.twoColumns}>
      <div>
        <label>Task Name</label>
        <input
          type="text"
          name="taskName"
          placeholder="Enter task name"
          value={task.taskName}
          onChange={(e) => {
            setTask({ ...task, taskName: e.target.value });
            setErrors({ ...errors, taskName: '' });
          }}
        />
        {errors.taskName && <span className={styles.error}>{errors.taskName}</span>}
      </div>
      <div>
        <label>Priority</label>
        <select
          name="priority"
          value={task.priority}
          onChange={(e) => setTask({ ...task, priority: e.target.value })}
        >
          <option value="P1">P1</option>
          <option value="P2">P2</option>
          <option value="P3">P3</option>
          <option value="P4">P4</option>
        </select>
      </div>
    </div>

    <div className={styles.fullWidth}>
      <label>Description</label>
      <textarea
        name="description"
        placeholder="Enter task description"
        value={task.description}
        onChange={(e) => {
          setTask({ ...task, description: e.target.value });
          setErrors({ ...errors, description: '' });
        }}
      />
      {errors.description && <span className={styles.error}>{errors.description}</span>}
    </div>

    <div className={styles.twoColumns}>
      <div>
        <label>Due Date</label>
        <input
          type="date"
          name="dueDate"
          value={task.dueDate}
          onChange={(e) => setTask({ ...task, dueDate: e.target.value })}
          min={today}
        />
      </div>
      <div>
        <label>Assignees</label>
        <Select
          isMulti
          options={teamMemberOptions}
          value={task.assignees}
          onChange={(selectedOptions) => setTask({ ...task, assignees: selectedOptions || [] })}
          placeholder="Select Assignees"
          className={styles.selectInput}
        />
      </div>
    </div>

    <div className={styles.buttonContainerForm}>
      <button type="button" onClick={onCancel}>Cancel</button>
      <button type="submit">{initialTaskData._id ? 'Update Task' : 'Create Task'}</button>
    </div>
  </form>
</div>

  );
};

export default TaskForm;
