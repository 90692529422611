import React, { useState, useEffect, useMemo } from 'react';
import { FaPlus, FaTasks } from 'react-icons/fa';
import TaskForm from './TaskForm';
import styles from './Teamheader.module.css';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const generateRandomTeamName = () => {
  const adjectives = ['Amazing', 'Bold', 'Creative', 'Dynamic', 'Epic'];
  const nouns = ['Squad', 'Crew', 'Group', 'Team', 'Alliance'];
  return `${adjectives[Math.floor(Math.random() * adjectives.length)]} ${
    nouns[Math.floor(Math.random() * nouns.length)]
  }`;
};

const Teamheader = ({ addTask, leadId }) => {
  const [showAddPeople, setShowAddPeople] = useState(false);
  const [showCreateTask, setShowCreateTask] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!leadId) return;

    const fetchTeamMembers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/teams/lead/${leadId}/members`);
        if (response.data.success) {
          setTeamMembers(response.data.members);
        } else {
          setTeamMembers([]);
          console.error('Failed to fetch team members:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching team members:', error.message);
        setTeamMembers([]);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/users?leadId=${leadId}`);
        console.log('API Response:', response.data);

        if (Array.isArray(response.data)) {
          setPeopleList(response.data); // Handle array response
        } else if (response.data.success) {
          setPeopleList(response.data.users || []); // Handle object response
        } else {
          console.error('Error fetching users: Unexpected response.', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching users:', error.response?.data?.message || error.message || 'Unknown error');
        setPeopleList([]);
      }
    };

    fetchTeamMembers();
    fetchUsers();
  }, [leadId]);

  const toggleAddPeople = () => setShowAddPeople((prev) => !prev);
  const toggleCreateTask = () => setShowCreateTask(!showCreateTask);

  const addPersonToTeam = async (person) => {
    if (teamMembers.some((member) => member.email.toLowerCase() === person.email.toLowerCase())) {
      showError(`${person.name} is already in the team.`);
      return;
    }

    const members = [{ id: person._id, name: person.name, email: person.email }];

    try {
      const response = await axios.post(`${API_BASE_URL}/teams/add-member`, {
        leadId,
        teamName: generateRandomTeamName(),
        members,
      });

      if (response.data.success) {
        setTeamMembers((prev) => [...prev, person]);
      } else {
        showError(response.data.message);
      }
    } catch (error) {
      showError('Failed to add member. Please try again.');
    }

    setShowAddPeople(false);
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(''), 3000);
  };

  const filteredPeople = useMemo(() => {
    return peopleList
      .filter(
        (person) =>
          !teamMembers.some((member) => member.email.toLowerCase() === person.email.toLowerCase()) &&
          (person.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            person.email.toLowerCase().includes(searchQuery.toLowerCase()))
      );
  }, [peopleList, teamMembers, searchQuery]);

  return (
    <div className={styles.teamWrapper}>
      <div className={styles.teamContainer}>
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className={styles.teamInfo}>
              <div className={styles.infoBlock}>
                <h4>Today's Date</h4>
                <p>{new Date().toLocaleDateString()}</p>
              </div>
              <div className={styles.infoBlock}>
  <h4>People in Team</h4>
  <div className={styles.profileStack}>
    {teamMembers.length > 0 ? (
      teamMembers.map((member, index) => (
        <img
          key={`${member._id}-${index}`}
          src={member.profilePicture || 'https://via.placeholder.com/40'}
          alt={member.name}
          title={member.email}
          className={styles.profilePicture}
        />
      ))
    ) : (
      <p className={styles.noMembersText}>No members in the team yet</p>
    )}
  </div>
</div>

            </div>

            <div className={styles.buttonContainer}>
              <button className={styles.button} onClick={toggleCreateTask}>
                <FaTasks /> Create Task
              </button>
              <button className={styles.button} onClick={toggleAddPeople}>
                <FaPlus /> Add People
              </button>
            </div>

            {showAddPeople && (
              <div className={styles.peopleList}>
                <input
                  type="text"
                  placeholder="Search people..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={styles.searchInput}
                />
                <ul>
                  {filteredPeople.map((person, index) => (
                    <li
                      key={`${person._id}-${index}`}
                      onClick={() => addPersonToTeam(person)}
                      title="Click to add"
                    >
                      <img
                        src={person.profilePicture || 'https://via.placeholder.com/40'}
                        alt={person.name}
                        className={styles.listProfilePicture}
                      />
                      <div>
                        <p>{person.name}</p>
                        <p>{person.email}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {showCreateTask && (
              <TaskForm
                leadId={leadId}
                teamMemberOptions={teamMembers.map((member) => ({
                  value: member.email,
                  label: member.name,
                }))}
                onSave={(task) => {
                  addTask(task);
                  toggleCreateTask();
                }}
                onCancel={toggleCreateTask}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Teamheader;
